import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeFourComponent } from './components/pages/home-four/home-four.component';
import { AboutComponent } from './components/pages/about/about.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { ContactComponent } from './components/pages/contact/contact.component';
import { ErrorComponent } from './components/pages/error/error.component';
import { ServicesTwoComponent } from './components/pages/services-two/services-two.component';
import { FeaturesOneComponent } from './components/pages/features-one/features-one.component';
import {BlogGridComponent} from './components/pages/blog-grid/blog-grid.component';
import {BlogDetailsComponent} from './components/pages/blog-details/blog-details.component';


const routes: Routes = [
    {path: '', component: HomeFourComponent},
    {path: 'inicio', component: HomeFourComponent},
    {path: 'nosotros', component: AboutComponent},
    {path: 'servicios', component: ServicesTwoComponent},
   /* {path: 'features-1', component: FeaturesOneComponent},
    {path: 'features-2', component: FeaturesTwoComponent},*/
    // {path: 'faq', component: FaqComponent},
    {path: 'error', component: ErrorComponent},
    {path: 'contacto', component: ContactComponent},
/*    {path: 'blog', component: BlogGridComponent},
    {path: 'blog-proyecto', component: BlogDetailsComponent}*/
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
