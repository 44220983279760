<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nuestros Servicios</h2>
            <p>Transformamos ideas en soluciones tecnológicas innovadoras.</p>
        </div>
    </div>
</div>

<!--    Cuadro Título "Conoce más de nuestros servicios" -->
<section class="ptb-100 bg-f4f6fc">
    <div class="container">
    </div>
    <!--                 Desarrollo Apliaciones-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".2s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/desarrollo_app.jpg" alt="Desarrollo Web y Apps">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">01</span>
                        <h3>Desarrollo Web y Apps</h3>
                        <p><b>Crea experiencias digitales a la medida</b></p>
                        <p>Diseñamos plataformas intuitivas y adaptables, desde sitios web responsivos hasta aplicaciones móviles y experiencias inmersivas con realidad aumentada y virtual. Integramos tecnologías de vanguardia para crear soluciones que impacten.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Sitios web responsivos y tiendas online.</li>
                            <li><i class='bx bx-badge-check'></i>Aplicaciones móviles, wearables y soluciones multiplataforma.</li>
                            <li><i class='bx bx-badge-check'></i>Experiencias inmersivas con Realidad Aumentada (AR) y Virtual (VR).</li>
                            <li><i class='bx bx-badge-check'></i>Automatización de procesos y APIs personalizadas.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--           Análisis de datos -->
    <div class="overview-item wow fadeInUp" data-wow-delay=".4s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">02</span>
                        <h3>Análisis de Datos</h3>
                        <p><b>Transforma datos en decisiones inteligentes</b></p>
                        <p>La combinación de informática, estadística y matemáticas permite encontrar patrones y generar insights valiosos. Unificamos y analizamos datos de múltiples fuentes, transformándolos en visualizaciones impactantes que facilitan la toma de decisiones estratégicas.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Gestión y análisis de datos (Data Management & Analytics).</li>
                            <li><i class='bx bx-badge-check'></i>Aplicación de *Data Science* para impulsar decisiones estratégicas.</li>
                            <li><i class='bx bx-badge-check'></i>Visualización interactiva y dashboards personalizados.</li>
                            <li><i class='bx bx-badge-check'></i>Soluciones escalables para grandes volúmenes de datos (<b>Big Data</b>).</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/Data_science.jpg" alt="Análisis de Datos">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Artificial Intelligence-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".6s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/ML.jpg" alt="Automatización Inteligente con IA">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">03</span>
                        <h3>Automatización Inteligente con IA</h3>
                        <p><b>Optimiza procesos con inteligencia artificial</b></p>
                        <p>La IA aprende, adapta y automatiza tareas complejas, imitando el razonamiento humano para resolver problemas de manera eficiente. Desde clasificar información hasta monitorear sistemas de seguridad o automatizar citas, la IA transforma la productividad empresarial.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Modelos predictivos y sistemas de Machine Learning aplicados.</li>
                            <li><i class='bx bx-badge-check'></i>Soluciones avanzadas de Deep Learning y análisis de imágenes.</li>
                            <li><i class='bx bx-badge-check'></i>IA generativa y procesamiento avanzado de lenguaje natural (NLP).</li>
                            <li><i class='bx bx-badge-check'></i>Automatización inteligente con Internet of Things (IoT).</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Soluciones en la nube-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".8s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">04</span>
                        <h3>Soluciones en la Nube</h3>
                        <p><b>Escalabilidad y flexibilidad al alcance de tu negocio</b></p>
                        <p>La nube elimina la necesidad de infraestructura física, permitiéndote enfocarte en lo que realmente importa: tu crecimiento. Te ayudamos a implementar soluciones escalables y seguras, optimizando costos y procesos.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Infraestructura en AWS, Azure y Google Cloud.
                            <li><i class='bx bx-badge-check'></i>Optimización de costos y procesos en la nube.</li>
                            <li><i class='bx bx-badge-check'></i>Despliegue de aplicaciones seguras y flexibles.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/cloud.jpg" alt="Soluciones en la Nube">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Diseño de Arquitectura-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".10s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/diseno_arquitectura.jpg" alt="Arquitectura y Optimización">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">05</span>
                        <h3>Arquitectura y Optimización</h3>
                        <p><b>Diseño de infraestructuras eficientes y escalables</b></p>
                        <p>Creamos soluciones seguras, escalables y confiables basadas en las mejores prácticas y tecnologías del mercado. Nos enfocamos en maximizar la eficiencia y asegurar la continuidad operativa de tu negocio.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Diseño de infraestructuras seguras, eficientes y escalables.</li>
                            <li><i class='bx bx-badge-check'></i>Optimización de recursos, reducción de costos y mejora del rendimiento.</li>
                            <li><i class='bx bx-badge-check'></i>Integración de DevOps y MLOps para flujos de trabajo ágiles.</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Consultorías -->
    <div class="overview-item wow fadeInUp" data-wow-delay=".12s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">06</span>
                        <h3>Consultorías Estratégicas</h3>
                        <p><b>Impulsa tu negocio con asesorías personalizadas</b></p>
                        <p>Te acompañamos en la transformación digital de tu empresa, brindando orientación técnica y estratégica. Identificamos oportunidades para optimizar procesos, reducir costos y escalar tu negocio utilizando tecnología de última generación.
                            <br>
                        </p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Asesoría en estrategias tecnológicas y transformación digital.</li>
                            <li><i class='bx bx-badge-check'></i>Evaluación de seguridad, escalabilidad y gobernanza de datos.</li>
                            <li><i class='bx bx-badge-check'></i>Optimización de costos y automatización de procesos empresariales.</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/consultoria.png" alt="Consultorías Estratégicas">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-100">
        <div class="free-trial-content margen-bloque-contacto">
            <h2>¿Tienes alguna idea en mente?</h2>
            <p>Por que tus ideas crean realidad</p>
            <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
        </div>
    </div>
</section>

