import { Component, AfterViewInit, ElementRef, ViewChild, OnInit } from '@angular/core';

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})

export class HomeFourComponent implements AfterViewInit, OnInit {
    @ViewChild('videoElement', { static: false }) videoElement!: ElementRef;
    @ViewChild('playButton', { static: false }) playButton!: ElementRef;

    currentSlide = 0;
    activeIndex: number = 0; // Primer proyecto abierto por defecto
    activeTab: number = 0;

    projects = [
        { image: 'assets/img/limitedstore.png', title: 'E-commerce Limitedstore', description: 'Tienda online creada en WordPress con WooCommerce para venta de calzados de seguridad. Incluye pasarela de pagos, protección anti-spam correos, seguimiento de conversiones y accesos directos a redes sociales.', link: 'https://limitedstore.cl' },
        { image: 'assets/img/verlan.png', title: 'Sitio web Verlan', description: 'Rediseño completo de un sitio PHP, con actualización de colores, imágenes y renovación de productos. Se mejoró la apariencia y la organización del sitio para ofrecer una experiencia más atractiva y fácil de navegar.',link: 'https://verlan.cl' },
        { image: 'assets/img/pranafruit.png', title: 'E-commerce Pranafruit', description: 'Tienda online creada con PrestaShop, enfocada en la venta de productos a base de arándano y otros productos naturales del Valle del Elqui. Diseño atractivo, de fácil navegación y accesos directos a redes sociales .', link: 'https://pranafruit.cl' },
        { image: 'assets/img/crm-ecomac.png', title: 'CRM Inmobiliario', description: 'Plataforma web desarrollada en Angular para gestionar clientes potenciales (leads) de una inmobiliaria. Automatiza la asignación de contactos a ejecutivos, muestra estadísticas gráficas y permite descargar datos históricos. Integra servicios de Firebase y Azure para un funcionamiento ágil y seguro.', link: '' },
        { image: 'assets/img/difuciencia.png', title: 'Sitio Web Difuciencia', description: 'Renovación completa del sitio de difusión científica CEAZA, con la implementación de una nueva plantilla gráfica, reorganización de contenidos y funcionalidades modernas para mejorar la experiencia del usuario.', link: 'https://difuciencia.cl' },
        { image: 'assets/img/bluelife.png', title: 'E-commerce Bluelife', description: 'Tienda online creada en PrestaShop para la venta de agua purificada. Se integraron modelos 3D y realidad aumentada para visualizar productos desde dispositivos móviles. Diseño moderno y experiencia interactiva para los clientes.', link: 'https://aguabluelide.cl' },
        { image: 'assets/img/tiempo-robado-editoras.png', title: 'E-commerce Tiempo Robado Editoras', description: 'Optimización visual y responsiva del sitio en WordPress, trabajando sobre la plantilla predeterminada del cliente. Automatización de la muestra de productos y artículos en el inicio, menú y pie de página, junto con una reorganización del contenido del menú y mejoras visuales en páginas clave.', link: 'https://tiemporobadoeditoras.cl' }
    ];
    toggleAccordion(index: number) {
        this.activeIndex = index;
    }
    selectTab(index: number) {
        this.activeTab = index;
    }
    ngOnInit(): void {
        this.autoSlide();
        this.addJsonLd();
    }
    ngAfterViewInit() {
        setTimeout(() => {
            this.playVideo();
        }, 500); // Pequeño retraso para asegurar que el DOM está listo
    }

    playVideo() {
        const videoElement = this.videoElement?.nativeElement as HTMLVideoElement;
        const playButton = this.playButton?.nativeElement as HTMLElement;

        if (videoElement) {
            videoElement.muted = true;  // Asegura que está silenciado
            videoElement.play()
                .then(() => {
                    console.log("Reproducción automática exitosa");
                    playButton.style.display = "none"; // Oculta el botón de reproducción
                })
                .catch(error => {
                    console.log("Reproducción bloqueada, mostrando botón:", error);
                    playButton.style.display = "flex"; // Muestra el botón si la reproducción está bloqueada
                });
        }
    }
    addJsonLd() {
        const script = document.createElement('script');
        script.type = 'application/ld+json';
        script.text = JSON.stringify({
            "@context": "https://schema.org",
            "@type": "VideoObject",
            "name": "Presentación de Mentanalytics",
            "description": "Descubre cómo Mentanalytics revoluciona la industria con Inteligencia Artificial y Data Science.",
            "thumbnailUrl": "https://www.mentanalytics.cl/assets/img/thumbnail.jpg",
            "uploadDate": "2025-01-29",
            "duration": "PT1M30S",
            "contentUrl": "https://www.mentanalytics.cl/assets/img/video_con_logo_mentanalytics.mp4",
            "embedUrl": "https://www.mentanalytics.cl/",
            "publisher": {
                "@type": "Organization",
                "name": "Mentanalytics",
                "logo": {
                    "@type": "ImageObject",
                    "url": "https://www.mentanalytics.cl/assets/img/logo_mentanalytics.png"
                }
            }
        });
        document.head.appendChild(script);
    }
    // Movimiento automático del carrusel
    autoSlide(): void {
        setInterval(() => {
            this.nextSlide();
        }, 4000); // Cambia cada 4 segundos
    }

    // Mueve al siguiente proyecto
    nextSlide(): void {
        this.currentSlide = (this.currentSlide + 1) % this.projects.length;
    }

    // Mueve al proyecto anterior
    prevSlide(): void {
        this.currentSlide = (this.currentSlide - 1 + this.projects.length) % this.projects.length;
    }

    // Actualiza el desplazamiento del carrusel
    getTranslateValue(): string {
        return `translateX(-${this.currentSlide * 100}%)`;
    }
}
