<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nuestra Empresa</h2>
<!--            <p>Mentanalytics</p>-->
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Acerca de   </span>
                    <h2>Mentanalytics</h2>
                    <p>Somos un equipo multidisciplinario de profesionales comprometidos con la transformación digital y la optimización de procesos. Aplicamos herramientas avanzadas de ingeniería y tecnología para impulsar la innovación y la mejora continua. Nuestro objetivo es convertir tus ideas en realidad, brindándote soluciones personalizadas y orientación estratégica con tecnología de vanguardia que se adapta a tus necesidades.</p>
                    <br>
                    <div class="quotes-style">
                        <p> <b >Mentanalytics:</b> </p>
                        <p style="font-style: italic">/ment-analytics/ </p>
                            <cite style="color: #4a6f8a">
                                El estudio de las partes necesarias para comprender el todo y tomar acción.
                                <br><br>
                                1. -ment (-mento en español): Representa acción, medio o herramienta para lograr un propósito.
                                <br>
                                2. analytcis (analítica): Para comprender un sistema, es necesario analizar sus partes de manera independiente e integrar el conocimiento.</cite>
                        <br>
                    </div>
                    <br>
                    <br>
                    <!-- Frase de Menta en su posición original (escritorio) -->
                    <div class="menta-text desktop-only">
                        <p class="">Pero <b>Mentanalytics es más que solo tecnología</b>. Su nombre también rinde homenaje a <b>Menta</b>, un integrante de cuatro patitas que nos inspira cada día a proteger y respetar el medioambiente y a los animales. 🌿🐾</p>
                    </div>

                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="about-image">
                    <div class="flex-parent-mision-vision">
                        <div class="flex-child-mision-vision">
                            <h3 >Misión</h3>
                            <p>Cocrear soluciones tecnológicas que impulsen el crecimiento y la productividad de empresas, ayudando a transformar ideas en realidades sostenibles e innovadoras.</p>
                        </div>
                        <br>
                        <div class="flex-child-mision-vision">
                            <h3>Visión</h3>
                            <p>Ser un referente en innovación y tecnología, integrando estándares de seguridad, eficiencia y sostenibilidad para contribuir a un futuro más consciente con el medioambiente y la economía circular.</p>
                        </div>
                    </div>
                    <img src="assets/img/nuestra_empresa.jpg" width="600" alt="Equipo Trabajo">
                </div>

                <!-- Frase de Menta en móviles (debajo de la imagen y antes del botón) -->
                <div class="menta-text mobile-only">
                    <p class="">Pero <b>Mentanalytics es más que solo tecnología</b>. Su nombre también rinde homenaje a <b>Menta</b>, un integrante de cuatro patitas que nos inspira cada día a proteger y respetar el medioambiente y a los animales. 🌿🐾</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container pb-100">
        <div class="free-trial-content margen-bloque-contacto">
            <h2>¿Tienes alguna idea en mente?</h2>
            <p>Por que tus ideas crean realidad</p>
            <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>
