<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nuestra Empresa</h2>
<!--            <p>Mentanalytics</p>-->
        </div>
    </div>
</div>

<section class="about-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <span class="sub-title">Acerca de   </span>
                    <h2>Mentanalytics</h2>
                    <p>Empresa integrada por profesionales de diversas áreas, que en conjunto buscan impulsar, digitalizar, optimizar y dar solución a procesos utilizando herramientas de las ciencias de la ingeniería, fomentando la innovación y mejora continua de los mismos.  Nuestro objetivo es ayudarte a realizar esa idea que quieras concretar, orientándote y brindándote las mejores opciones que se adaptan a tus necesidades con tecnología de vanguardia.</p>
                    <br>
                    <div class="quotes-style">
                        <p> <b >Mentanalytics:</b> </p>
                        <p style="font-style: italic">/ment-analytics/ </p>
                            <cite style="color: #4a6f8a">
                                Es el estudio de las partes necesarias para entender el todo y realizar la acción.
                                <br><br>
                                1. -ment (-mento en español) se refiere a la acción, medio o herramienta por el cual se realiza algo, una acción.
                                <br>
                                2. analytcis (analítica) nos dice que para entender un todo, se deben estudiar sus partes por separados e integrar el conomiento. </cite>
                        <br>
                    </div>
                    <br>
                    <br>
                        <p>Además, <b>"menta"</b> es el nombre de un integrante de cuatro patitas muy importante para la empresa, que nos motiva a proteger y amar a los animales y el medio ambiente.</p>
                        <br>
                        <br>
                        <br>
                    <!--<div class="flex-parent-mision-vision">
                        <div class="flex-child-mision-vision">
                            <h3 >Misión</h3>
                            <p>Impulsar el crecimiento y productividad de pequeña, mediana y grandes empresas.</p>
                        </div>
                        <br>
                        <div class="flex-child-mision-vision">
                            <h3 class="flex-child-mision-vision">Visión</h3>
                            <p>Ser referente en innovación utilizando lo más altos estándares en tecnología, seguridad y medioambiente.</p>
                        </div>
                    </div>-->


                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-image">
                    <div class="flex-parent-mision-vision">
                        <div class="flex-child-mision-vision">
                            <h3 >Misión</h3>
                            <p>Impulsar el crecimiento y productividad de pequeña, mediana y grandes empresas.</p>
                        </div>
                        <br>
                        <div class="flex-child-mision-vision">
                            <h3 class="flex-child-mision-vision">Visión</h3>
                            <p>Ser referente en innovación utilizando lo más altos estándares en tecnología, seguridad y medioambiente.</p>
                        </div>
                    </div>
                    <img src="assets/img/nuestra_empresa.jpg" alt="Equipo Trabajo">
                </div>
            </div>
        </div>
    </div>
</section>

<!--<section class="our-loving-clients ptb-100 bg-f4f5fe">
    <div class="container">
        <div class="section-title">
            <h2>Our Loving Clients</h2>
        </div>
        <div class="clients-logo-list align-items-center">
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".2s">
                <a href="#">
                    <img src="assets/img/clients-image/img1.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".4s">
                <a href="#">
                    <img src="assets/img/clients-image/img2.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".6s">
                <a href="#">
                    <img src="assets/img/clients-image/img3.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay=".8s">
                <a href="#">
                    <img src="assets/img/clients-image/img4.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1s">
                <a href="#">
                    <img src="assets/img/clients-image/img5.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.2s">
                <a href="#">
                    <img src="assets/img/clients-image/img6.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.4s">
                <a href="#">
                    <img src="assets/img/clients-image/img7.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.6s">
                <a href="#">
                    <img src="assets/img/clients-image/img8.png" alt="image">
                </a>
            </div>
            <div class="single-clients-logo wow fadeInUp" data-wow-delay="1.8s">
                <a href="#">
                    <img src="assets/img/clients-image/img9.png" alt="image">
                </a>
            </div>
        </div>
    </div>
</section>-->

<!--<section class="video-presentation-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Why choose us to watch this video know more</h2>
        </div>
        <div class="video-box">
            <img src="assets/img/video-bg.jpg" class="main-image" alt="image">
            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="funfacts-inner">
            <div class="row">
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".2s">
                        <i class='bx bx-list-check'></i>
                        <h3><span class="odometer" data-count="900">00</span><span class="sign-icon">+</span></h3>
                        <p>Completed Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".4s">
                        <i class='bx bx-smile'></i>
                        <h3><span class="odometer" data-count="850">00</span><span class="sign-icon">+</span></h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6">
                    <div class="single-funfacts funfact-style-two wow fadeInLeft" data-wow-delay=".6s">
                        <i class='bx bx-grid-small'></i>
                        <h3><span class="odometer" data-count="120">00</span><span class="sign-icon">+</span></h3>
                        <p>Ongoing Projects</p>
                    </div>
                </div>
                <div class="col-lg-3 col-6 col-sm-6 wow fadeInLeft" data-wow-delay=".8s">
                    <div class="single-funfacts funfact-style-two">
                        <i class='bx bxs-award'></i>
                        <h3><span class="odometer" data-count="50">00</span><span class="sign-icon">+</span></h3>
                        <p>Winning Awards</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="contact-cta-box mwidth-1000 wow fadeInUp" data-wow-delay="1s">
            <h3>Have any question about us?</h3>
            <p>Don't hesitate to contact us.</p>
            <a routerLink="/contact" class="default-btn"><i class="bx bxs-edit-alt"></i> Contact Us<span></span></a>
        </div>
    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>-->


<!--<section class="app-download-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-5 col-md-12">
                <div class="app-download-image wow fadeInLeft" data-wow-delay=".3s">
                    <img src="assets/img/mobile.png" alt="image">
                </div>
            </div>
            <div class="col-lg-7 col-md-12">
                <div class="app-download-content">
                    <span class="sub-title">Download App</span>
                    <h2>Supporting your customers on the go with our mobile app</h2>
                    <div class="btn-box">
                        <a href="#" class="apple-store-btn"><img src="assets/img/applestore.png" alt="image">Download on the<span>Apple Store</span></a>
                        <a href="#" class="play-store-btn"><img src="assets/img/playstore.png" alt="image">Get it on<span>Google Play</span></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<!--<section class="feedback-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Whats Our Clients Said About <span>Jexsa</span></h2>
        </div>
        <div class="feedback-slides owl-carousel owl-theme">
            <div class="single-feedback-item">
                <img src="assets/img/woman1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Sarah Taylor</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/woman2.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Olivar Lucy</h3>
                        <span>CEO at EnvyTheme.com</span>
                    </div>
                </div>
            </div>
            <div class="single-feedback-item">
                <img src="assets/img/man1.png" alt="image">
                <div class="feedback-desc">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                    <div class="rating">
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                        <i class="bx bxs-star"></i>
                    </div>
                    <div class="client-info">
                        <h3>Steven Smith</h3>
                        <span>CEO at Envato</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>-->

<section class="free-trial-area pb-100 bg-f4f5fe">
    <div class="container pb-100">
        <div class="free-trial-content margen-bloque-contacto">
            <h2>¿Tienes alguna idea en mente?</h2>
            <p>Por que tus ideas crean realidad</p>
            <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
        </div>
    </div>
    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>
