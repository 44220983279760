import { Component, OnInit, OnDestroy } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';
import axios from 'axios';
declare var grecaptcha: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit, OnDestroy {
    FormData: FormGroup;
    private http: any;
    showSuccessMessage: boolean = false;
    showErrorMessage: boolean = false;
    formSubmitted: boolean = false;

    constructor(private apiService: ApiService, private builder: FormBuilder) {}

    ngOnInit() {
        this.FormData = this.builder.group({
            nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
            email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
            asunto: new FormControl('', [Validators.required]),
            mensaje: new FormControl('', [Validators.required]),
            telefono: new FormControl('', [Validators.required]),
        });
        // Suscribirse a los cambios en el formulario
        this.FormData.valueChanges.subscribe(() => {
            this.formSubmitted = false;
        });
        // Cargar reCAPTCHA solo en la página de contacto
        this.loadRecaptcha();
    }
    // Función para cargar el script de reCAPTCHA solo en esta página
    loadRecaptcha() {
        if (!document.getElementById("recaptcha-script")) {
            const recaptchaScript = document.createElement('script');
            recaptchaScript.src = "https://www.google.com/recaptcha/api.js?render=6LewME8nAAAAAHgfT_H0UTUVWk_7fpOidpRcLJYz";
            recaptchaScript.id = "recaptcha-script";
            recaptchaScript.async = true;
            recaptchaScript.defer = true;
            document.body.appendChild(recaptchaScript);

            // Inyectar estilos directamente para el reCAPTCHA al agregar el script
            const style = document.createElement('style');
            style.textContent = `
            .grecaptcha-badge {
                bottom: 100px !important;  /* Ajusta este valor según necesites */
            }
        `;
            document.head.appendChild(style);
        }
    }


    onSubmit() {
        if (this.FormData.valid) {
            if (typeof grecaptcha !== 'undefined') { // Verifica si grecaptcha está disponible
                grecaptcha.ready(() => {
                    grecaptcha.execute('6LewME8nAAAAAHgfT_H0UTUVWk_7fpOidpRcLJYz', { action: 'submit' }).then((token) => {
                        this.FormData.value.token = token;
                        this.apiService.apiLogicApp(this.FormData.value).subscribe(
                            (response) => {
                                this.showSuccessMessage = true;
                                this.showErrorMessage = false;
                                this.formSubmitted = true;
                                this.FormData.disable();
                            },
                            (error) => {
                                this.showSuccessMessage = false;
                                this.showErrorMessage = true;
                            }
                        );
                    });
                });
            }
        }
    }

    // Modifica la función ngOnDestroy para forzar la recarga de la página sin el recaptcha
    ngOnDestroy() {
        const recaptchaScript = document.getElementById("recaptcha-script");
        if (recaptchaScript) {
            recaptchaScript.remove();
            window.location.reload(); // Fuerza la recarga de la página para eliminar la instancia de reCAPTCHA
        }
    }
}
