<section class="video-container">
    <h1 class="hidden-title">Video corporativo Mentanalytics</h1>
    <div class="container-video">
        <video #videoElement id="videoElement" width="100%" autoplay loop muted playsinline>
            <source src="assets/img/video_con_logo_mentanalytics.mp4" type="video/mp4">
            Tu navegador no soporta el video.
        </video>
        <div #playButton class="play-button-container" (click)="playVideo()">
            <img class="play-button-svg" src="assets/img/circle-play-blanco.png" alt="Reproducir video">
        </div>
    </div>
</section>

<section class="services-area bg-left-color bg-left-shape bg-f4f6fc ptb-100">
    <div  class="container-fluid">
        <div class="row align-items-center">
            <div data-wow-delay=".3s" class="services-image wow animate__fadeInLeft">
                <div class="image">
                    <img src="assets/img/soluciones-tecnologicas-mentanalytics.png" alt="soluciones-tecnologicas-mentanalytics">
                </div>
            </div>
            <div class="services-content it-service-content">
                <div class="content">
                    <h2 >Soluciones Tecnológicas para Impulsar tu Negocio</h2>
                    <p>En <strong>Mentanalytics</strong>, ofrecemos soluciones innovadoras en desarrollo web,
                        análisis de datos e inteligencia artificial para potenciar la toma de decisiones y optimizar procesos.
                        Trabajamos con tecnologías de vanguardia en la nube y experiencias interactivas para transformar tu negocio.</p>
                    <div class="row">
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div  class="feature-box"><i class="bx bxs-badge-check"></i> Desarrollo web y Apps</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box"><i class="bx bxs-badge-check"></i> Análisis de datos</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box"><i  class="bx bxs-badge-check"></i> Automatización con IA</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div  class="feature-box"><i class="bx bxs-badge-check"></i> Solucione en la Nube</div>
                        </div>
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box"><i  class="bx bxs-badge-check"></i> Arquitectura y Optimización</div>
                        </div>
                        <div  class="col-lg-6 col-md-6 col-sm-6">
                            <div class="feature-box"><i  class="bx bxs-badge-check"></i> Consultorías y Estrategias</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<section class="features-area pt-100 pb-100 bg-fffff" #target>
    <div class="container max-width-1290">
        <div class="section-title">
            <h2>Nuestros Servicios</h2>
        </div>
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-code-alt bg-ff612f blt-radius-0'></i>
                        </div>
                        <h3>Desarrollo Web y Apps</h3>
                        <p>Creación de sitios, tiendas online y experiencias interactivas.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> E-commerce estándar y personalizados.</li>
                            <li><i class='bx bx-badge-check'></i> Webs interactivas con realidad aumentada.</li>
                            <li><i class='bx bx-badge-check'></i> Integraciones con APIs y sistemas.</li>
                        </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-line-chart bg-ffb700 blt-radius-0'></i>
                        </div>
                        <h3>Análisis de Datos</h3>
                        <p>Transformamos datos en información valiosa para la toma de decisiones.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Análisis y visualización interactiva.</li>
                            <li><i class='bx bx-badge-check'></i> Métricas para marketing digital.</li>
                            <li><i class='bx bx-badge-check'></i> Data Science aplicado a negocios.</li>
                        </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-brain bg-00d280 blt-radius-0'></i>
                        </div>
                        <h3>Automatización con IA</h3>
                        <p>Soluciones inteligentes para optimizar procesos y decisiones.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Modelos predictivos y Machine Learning.</li>
                            <li><i class='bx bx-badge-check'></i> IA generativa y Procesamiento de Lenguaje.</li>
                            <li><i class='bx bx-badge-check'></i> Sistemas con IA y automatización.</li>
                        </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-cloud bg-6610f2 blt-radius-0'></i>
                        </div>
                        <h3>Soluciones en la Nube</h3>
                        <p>Infraestructura escalable y segura en AWS, Azure, Google Cloud y Firebase.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Aplicaciones web y bases de datos en la nube.</li>
                            <li><i class='bx bx-badge-check'></i> Infraestructura segura y flexible.</li>
                            <li><i class='bx bx-badge-check'></i> Optimización con DevOps y serverless.</li>
                        </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>



                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-sitemap'></i>
                        </div>
                        <h3>Arquitectura y Optimización</h3>
                        <p>Diseño de infraestructuras eficientes y escalables adaptadas a las necesidades del negocio.</p>
                            <ul>
                                <li><i class='bx bx-badge-check'></i> Optimización de recursos y costos.</li>
                                <li><i class='bx bx-badge-check'></i> Alta disponibilidad y seguridad.</li>
                                <li><i class='bx bx-badge-check'></i> Infraestructura adaptable y eficiente.</li>
                            </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 col-sm-6 border-box">
                    <div class="single-features-box overview-content">
                        <div class="icon">
                            <i class='bx bx-conversation bg-ff612f blt-radius-0'></i>
                        </div>
                        <h3>Consultorías y Estrategias</h3>
                        <p>Transformamos procesos con soluciones tecnológicas personalizadas.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Automatización y optimización de procesos.</li>
                            <li><i class='bx bx-badge-check'></i> Análisis y tracking de datos digitales.</li>
                            <li><i class='bx bx-badge-check'></i> Implementación de APIs y medición digital.</li>
                        </ul>
                        <a routerLink="/servicios" class="read-more">Ver más <i class='bx bx-right-arrow-alt'></i></a>
                    </div>
                </div>
            </div>
        </div>


        <div class="shape9">
            <img src="assets/img/shape/shape9.png" alt="image">
        </div>
    </div>

</section>

<!--<section class="latest-projects ptb-100 bg-light">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros Proyectos Recientes</h2>
            <p>Descubrí algunos de los sitios web que hemos desarrollado para nuestros clientes.</p>
        </div>

        <div class="row">
            &lt;!&ndash; Proyecto 1 &ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/limitedstore.jpg" alt="Sitio Web Limitedstore">
                    <h3>E-commerce Limitedstore</h3>
                    <p>Desarrollo de una tienda online de venta de calzados de seguridad con Wordpress.</p>
                    <a href="https://limitedstore.cl" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>

            &lt;!&ndash;Proyecto 2&ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/Verlan.png" alt="Sitio Web Verlan">
                    <h3>Sitio web Verlan</h3>
                    <p>Actualización de sitio web de calzados de seguridad creado con php.</p>
                    <a href="https://verlan.cl" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>

&lt;!&ndash;            Proyecto 3&ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/pranafruit.png" alt="Sitio Web Pranafruit">
                    <h3>E-commerce Pranafruit</h3>
                    <p>Desarrollo de una tienda online de venta de concentrados de arándos y productos naturales con Prestashop.</p>
                    <a href="https://pranafruit.cl" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>
&lt;!&ndash;            Proyecto 4&ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/crm-ecomac.png" alt="CRM Ecomac">
                    <h3>CRM Inmobiliario</h3>
                    <p>Desarrollo de sistema de gestión de leads para inmobiliaria con ecosistema en nube de google y creado en Angular.</p>
&lt;!&ndash;<a href="https://aguabluelide.cl" target="_blank" class="btn-visit">Visitar sitio</a>&ndash;&gt;
                </div>
            </div>
&lt;!&ndash;            Proyecto 5&ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/difuciencia.png" alt="Sitio Web Difuciencia">
                    <h3>Sitio web Difuciencia</h3>
                    <p>Renovación completa de sitio web informartivo de difusión científica de CEAZA con Wordpress.</p>
                    <a href="https://difuciencia.cl" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>
&lt;!&ndash;           Proyecto 6&ndash;&gt;
            <div class="col-lg-4 col-md-6">
                <div class="project-card">
                    <img src="assets/img/bluelife.png" alt="Sitio Web Agua Bluelife">
                    <h3>E-commerce Bluelife</h3>
                    <p>Desarrollo de una tienda online de venta de agua purificada e intefración de modelos 3D con visualización de realidad aumentada con Prestashop.</p>
                    <a href="https://aguabluelide.cl" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>
        </div>
    </div>
</section>-->


<section class="latest-projects ptb-100 green-bk">
    <div class="container">
        <div class="section-title-projects">
            <h2>Últimos Proyectos</h2>
            <p>Descubre algunos de los sitios web que hemos desarrollado para nuestros clientes.</p>
        </div>

        <!-- Carrusel -->
        <!--<div class="carousel-container">
            <div class="carousel-track" [style.transform]="getTranslateValue()">
                <div class="project-card " *ngFor="let project of projects">
                    <img [src]="project.image" [alt]="project.title" />
                    <h3>{{ project.title }}</h3>
                    <p>{{ project.description }}</p>
                    <a *ngIf="project.link" [href]="project.link" target="_blank" class="btn-visit">Visitar sitio</a>
                </div>
            </div>

            &lt;!&ndash; Botones de Navegación &ndash;&gt;
            <button class="carousel-btn prev" (click)="prevSlide()">❮</button>
            <button class="carousel-btn next" (click)="nextSlide()">❯</button>
        </div>-->

    </div>

    <div class="container pt-70">
        <div class="accordion-layout">
            <!-- Lista de proyectos (Títulos) -->
            <div class="accordion-titles">
                <div
                    *ngFor="let project of projects; let i = index"
                    [class.active]="activeIndex === i"
                    (click)="toggleAccordion(i)">
                    {{ project.title }}
                </div>
            </div>

            <!-- Detalle del proyecto (Imagen y descripción) -->
            <div class="accordion-details" *ngIf="projects[activeIndex]">
                <img [src]="projects[activeIndex].image" [alt]="projects[activeIndex].title" />
                <h3>{{ projects[activeIndex].title }}</h3>
                <p>{{ projects[activeIndex].description }}</p>
                <a *ngIf="projects[activeIndex].link" [href]="projects[activeIndex].link" target="_blank" class="btn-visit">Visitar sitio</a>
            </div>
        </div>
    </div>
   <!-- <div class="container pt-70">
        <div class="tabs-container">
            &lt;!&ndash; Títulos (Tabs) &ndash;&gt;
            <div class="tabs-header">
                <div
                    *ngFor="let project of projects; let i = index"
                    [class.active]="activeTab === i"
                    (click)="selectTab(i)">
                    {{ project.title }}
                </div>
            </div>

            &lt;!&ndash; Contenido del Tab &ndash;&gt;
            <div class="tabs-content" *ngIf="projects[activeTab]">
                <img [src]="projects[activeTab].image" [alt]="projects[activeTab].title" />
                <h3>{{ projects[activeTab].title }}</h3>
                <p>{{ projects[activeTab].description }}</p>
                <a *ngIf="projects[activeTab].link" [href]="projects[activeTab].link" target="_blank" class="btn-visit">Visitar sitio</a>
            </div>
        </div>
    </div>-->
</section>
<section class="pt-70">
        <div class="container ">
            <div class="subscribe-content">
                <h2>Partner Fizz Estudio Gráfico</h2>
                <div class="logos-partner">
                    <a href="https://fizz.cl" target="_blank">
                        <img src="assets/img/logo-fizz.svg" alt="Logo Fizz Estudio Gráfico">
                    </a>
                    <img src="assets/img/logo_mentanalytics.png" width="400">
                </div>

                <div class="shape14">
                    <img src="assets/img/shape/shape13.png" alt="image"></div>
                <div class="shape15">
                    <img src="assets/img/shape/shape14.png" alt="image">
                </div>
                <div  class="shape16">
                    <img src="assets/img/shape/shape15.png" alt="image">
                </div>
                <div class="shape17">
                    <img src="assets/img/shape/shape16.png" alt="image">
                </div>
                <div class="shape18">
                    <img  src="assets/img/shape/shape17.png" alt="image">
                </div>
            </div>
        </div>
</section>


<section class="video-presentation-area pb-100 pt-100">
    <div class="container">
        <div class="section-title">
            <h2>Nuestra Metodología </h2>
        </div>
        <div class="video-box">
            <img src="assets/img/metodologia-mentanalytics-1200x448.png" class="main-image" alt="nuestra_metodología">
            <!--            <a href="https://www.youtube.com/watch?v=0gv7OC9L2s8" class="video-btn popup-youtube"><i class="bx bx-play"></i></a>-->
            <div class="shape1"><img src="assets/img/shape/shape1.png" alt="image"></div>
            <div class="shape2"><img src="assets/img/shape/shape2.png" alt="image"></div>
            <div class="shape3"><img src="assets/img/shape/shape3.png" alt="image"></div>
            <div class="shape4"><img src="assets/img/shape/shape4.png" alt="image"></div>
            <div class="shape5"><img src="assets/img/shape/shape5.png" alt="image"></div>
            <div class="shape6"><img src="assets/img/shape/shape6.png" alt="image"></div>
        </div>
        <div class="container pt-100">
            <div class="free-trial-content margen-bloque-contacto">
                <h2>¿Tienes alguna idea en mente?</h2>
                <p>Por que tus ideas crean realidad</p>
                <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
            </div>
        </div>

    </div>
    <div class="shape-map1"><img src="assets/img/map1.png" alt="image"></div>
    <div class="shape7"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape8"><img src="assets/img/shape/shape8.png" alt="image"></div>
    <div class="shape9"><img src="assets/img/shape/shape9.png" alt="image"></div>
</section>


<section class="free-trial-area pb-100">

    <div class="shape10"><img src="assets/img/shape/shape10.png" alt="image"></div>
    <div class="shape11"><img src="assets/img/shape/shape7.png" alt="image"></div>
    <div class="shape12"><img src="assets/img/shape/shape11.png" alt="image"></div>
    <div class="shape13"><img src="assets/img/shape/shape12.png" alt="image"></div>
</section>
    <!-- Google Tag Manager (noscript) -->
    <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M4ZNG2LJ"
                      height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
    <!-- End Google Tag Manager (noscript) -->
