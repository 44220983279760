import { Component, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-home-four',
  templateUrl: './home-four.component.html',
  styleUrls: ['./home-four.component.scss']
})

export class HomeFourComponent implements AfterViewInit {
    // @ViewChild('myVideo', {static: false}) video: ElementRef;
    ngAfterViewInit() {
        // Llamar a la función playVideo después de que la vista se haya inicializado
        this.playVideo();
    }
    playVideo() {
        const videoElement = document.querySelector("#videoElement") as HTMLVideoElement;
        const playButton = document.querySelector(".play-button-container") as HTMLElement;
        videoElement.play()
            .then(() => {
                console.log("Reproducción exitosa");
                playButton.style.opacity = "0"; // Ocultar el botón de reproducción cuando se inicia el video
            })
            .catch(error => {
                console.log("Error de reproducción:", error);
            });
    }
}
