<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Nuestros Servicios</h2>
            <p>Conoce más de nuestras áreas de expertise</p>
        </div>
    </div>
</div>

<!--    Cuadro Título "Conoce más de nuestros servicios" -->
<section class="ptb-100 bg-f4f6fc">
    <div class="container">
        <!--<div class="section-title">
            <h2 class="mb-2">Conoce más de nuestros servicios</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>-->
    </div>
    <!--                Data Science-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".2s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/Data_science.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">01</span>
                        <h3>Data Science</h3>
                        <p>Este campo mezcla áreas de informática, estadística y matemáticas para obtener información de interés de un conjunto de datos, analizando y encontrando patrones para tomar una mejor decisión de negocio. Por ejemplo, una empresa que ofrece servicios de delivery recopila información a partir de formularios de contacto desde su página web, de encuestas telefónicas, de consultas mediante un chat, u otros canales de contacto. Tiene toda esta información recopilada en distintos Excel, Softwares,  Words, y quiere saber por ejemplo, que zona geográfica es la más solicitada, por qué vía se solicita con más frecuencia el servicio, cuanto tiempo se demora en promedio desde que se solicita el delivery hasta que se entrega al cliente. Esas diversas fuentes de información se unifican, se analizan y se obtenien los resultados deseados, permitiendo llevar estos resultados a una visualización que impacte en la toma de decisiones.
                            <br>
                        <br><br></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Data Management</li>
                            <li><i class='bx bx-badge-check'></i>Data Analytics</li>
                            <li><i class='bx bx-badge-check'></i>Data Visualization</li>
                            <li><i class='bx bx-badge-check'></i>Big Data</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Artificial Intelligence-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".4s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">02</span>
                        <h3>Inteligencia Artificial (IA)</h3>
                        <p>La inteligencia artificial es el futuro, son algoritmos complejos que aprenden automáticamente cualquier cosa que desees que aprenda, básicamente, imitar el comportamiento humano, para por ejemplo, tomar decisiones, agendar una cita, clasificar información, vigilar cámaras de seguridad, etc. Dependiendo de la necesidad que se desea automatizar se utilizan diferentes tipos de aprendizaje automático, como Machine, Deep Learning  e IoT.

                            <br>
                            <br>
                            <br></p>

                        <ul>
                            <li><i class='bx bx-badge-check'></i>Machine Learning</li>
                            <li><i class='bx bx-badge-check'></i>Deep Learning</li>
                            <li><i class='bx bx-badge-check'></i>Natural Languaje Processing</li>
                            <li><i class='bx bx-badge-check'></i>Internet of Things</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/ML.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Clouds -->
    <div class="overview-item wow fadeInUp" data-wow-delay=".6s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/cloud.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">03</span>
                        <h3>Clouds</h3>
                        <p>Las nubes nos permiten despreocuparnos de la infraestructura(equipos, servidores, redes, instalaciones, etc) para enfocarnos realmente en lo que importa, nuestro negocio. Entre los servicios que ofrecen se encuentran, por ejemplo, almacenamiento de datos, integración de servicios,  despliegue de aplicaciones, escalabilidad de proyectos y mucho más!. Existe gran diversidad de proveedores, siendo las nubes más importantes hoy en día Amazon Web Services(AWS), Google Platform Cloud(GPC) y Microsoft Azure.
                            <br>
                            <br></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Amazon Web Services</li>
                            <li><i class='bx bx-badge-check'></i>Microsoft Azure</li>
                            <li><i class='bx bx-badge-check'></i>Google Cloud Platform</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Desarrollo Apliaciones-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".8s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">04</span>
                        <h3>Desarrollo de Aplicaciones</h3>
                        <p>Creamos plataformas según las necesidades del usuario y la experiencia que quiera generar al utilizar la aplicación. Puede ser por ejemplo, crear una página web, responsivo para distintos dispositivos. Integración de tecnología de realidad aumentada y realidad virtual, así como creación de aplicaciones para dispositivos inteligentes como Smart Watch, Smart TV, entre otros. En esta esa área se puede integrar servicios de nubes, inteligencia artificial, y mucho otros.


                            <br>
                            <br></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Páginas Webs</li>
                            <li><i class='bx bx-badge-check'></i>Realidad Aumentada</li>
                            <li><i class='bx bx-badge-check'></i>Realidad Virtual</li>
                            <li><i class='bx bx-badge-check'></i>Desarrollo Aplicación Mobile</li>
                            <li><i class='bx bx-badge-check'></i>Desarrollo Aplicación Wearable</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/desarrollo_app.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Diseño de Arquitectura-->
    <div class="overview-item wow fadeInUp" data-wow-delay=".10s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-left-img">
                        <img src="assets/img/diseno_arquitectura.jpg" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">05</span>
                        <h3>Diseño de Arquitectura</h3>
                        <p>Desarrollamos soluciones orientadas a resolver tus problemas utilizando las mejores prácticas y tecnologías. Contamos con certificación en las mejores nubes del mercado. Nuestro enfoque es trasladar los requerimientos de tu negocio hacia soluciones seguras, escalables y confiables en la nube.
                            <br>
                            <br></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>DevOps</li>
                            <li><i class='bx bx-badge-check'></i>Machine Learning Operations (MLOps)</li>
                            <li><i class='bx bx-badge-check'></i>DataOps</li>
                            <li><i class='bx bx-badge-check'></i>Disaster Recovery</li>
                            <li><i class='bx bx-badge-check'></i>Data Governance</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--                Consultorías -->
    <div class="overview-item wow fadeInUp" data-wow-delay=".12s">
        <div class="container max-width-1290">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <div class="overview-content pl-3">
                        <span class="number">06</span>
                        <h3>Consultorías</h3>
                        <p>Ofrecemos orientación técnica y solución en diversas áreas de la tecnología, tales como Machine Learning, Clouds, DevOps, Diseño de Arquitecturas, Data Science, entre otros. Podemos brindarte soluciones para mejorar o evaluar la seguridad de tus procesos, las tecnologías que necesitas para automatizar, optimizar, y proporcionar escalabilidad en tus procesos, así como también evaluar los costos que conllevarían, todo esto siempre evaluado según tus necesidades y tecnología que quieras implementar.

                            <br>
                            <br></p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i>Seguridad</li>
                            <li><i class='bx bx-badge-check'></i>Escalabilidad</li>
                            <li><i class='bx bx-badge-check'></i>Tecnologías</li>
                            <li><i class='bx bx-badge-check'></i>Costos</li>
                            <li><i class='bx bx-badge-check'></i>Optimización</li>
                            <li><i class='bx bx-badge-check'></i>Automatización</li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-6">
                    <div class="overview-right-img">
                        <img src="assets/img/consultoria.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="container pt-100">
        <div class="free-trial-content margen-bloque-contacto">
            <h2>¿Tienes alguna idea en mente?</h2>
            <p>Por que tus ideas crean realidad</p>
            <a routerLink="/contacto" class="default-btn"><i class="bx bxs-hot"></i> Contactanos<span></span></a>
        </div>
    </div>
</section>

