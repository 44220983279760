import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
// Import the functions you need from the SDKs you need
/*import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: 'AIzaSyDDnCDM4SumJvXRDdFWHqbXBBxp5usOTS0',
    authDomain: 'mentanalytics-webpage-angular.firebaseapp.com',
    projectId: 'mentanalytics-webpage-angular',
    storageBucket: 'mentanalytics-webpage-angular.appspot.com',
    messagingSenderId: '820691623064',
    appId: '1:820691623064:web:be87ee84c3a808738c44ed',
    measurementId: 'G-KL1X3G450Y'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);*/

if (environment.production) {
    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
