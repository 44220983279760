<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row" style="justify-content: center;">
          <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/logo_mentanalytics_blanco.png" width="50%"  alt="logo-mentanalytics-footer"></a>
                    </div>
                </div>
            </div>
            <!--            Informacción contacto-->
            <div class="col-lg-4 col-md-6 col-sm-6 flex-center">
                <div class="single-footer-widget">
                    <h3>Contáctanos</h3>
                    <ul class="footer-contact-info">
                        <li><i class='bx bxs-map-pin extra-icon'></i><a href="https://goo.gl/maps/BQjZwMKSLk9gvxV26" target="_blank">Santiago, Chile</a></li>
                        <li><i class='bx bx-envelope extra-icon'></i><a href="mailto:info@mentanalytics.cl">info@mentanalytics.cl</a></li>
                        <li><i class='bx bxl-whatsapp extra-icon'></i><a href="https://wa.me/56931169879?text=¡Hola Mentanalytics! Me gustaría conversar sobre un proyecto que tengo en mente..." target="_blank">+569 3116 98 79</a></li>
                    </ul>
                    <!-- <ul class="social">
                         <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                         <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                         <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                         <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                     </ul>-->
                </div>
            </div>
            <!--            Empresa-->
            <div class="col-lg-4 col-md-6 col-sm-6 flex-center">
                <div class="single-footer-widget">
                    <h3>Mapa del sitio</h3>
                    <ul class="services-list">
                        <li><a routerLink="/nosotros">Sobre Nosotros</a></li>
                        <li><a routerLink="/servicios">Servicios</a></li>
                        <li><a routerLink="/contacto">Contáctanos</a></li>
<!--                        <li><a routerLink="/blog">Blog</a></li>-->
                    </ul>
                </div>
            </div>
            <!--            Soporte-->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                 <div class="single-footer-widget">
                     <h3>Soporte</h3>
                     <ul class="support-list">
                         <li><a routerLink="/faq">FAQ's</a></li>
                         <li><a routerLink="/">Privacy Policy</a></li>
                         <li><a routerLink="/">Terms & Conditions</a></li>
 &lt;!&ndash;                        <li><a routerLink="/">Community</a></li>&ndash;&gt;

                    </ul>
                </div>
            </div> -->

        </div>
        <div class="copyright-area">
            <p>Copyright @2025 Mentanalytics. </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
