<footer class="footer-area">
    <div class="divider"></div>
    <div class="container">
        <div class="row" style="justify-content: center;">
          <!--  <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/ment_analytics_2.png" width="50%"  alt="image"></a>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                </div>
            </div>-->
            <!--            Empresa-->
            <div class="col-lg-4 col-md-6 col-sm-6 flex-center">
                <div class="single-footer-widget">
                    <h3>Empresa</h3>
                    <ul class="services-list">
                        <li><a routerLink="/nosotros">Sobre Nosotros</a></li>
                        <li><a routerLink="/servicios">Servicios</a></li>
                        <li><a routerLink="/contacto">Contáctanos</a></li>
<!--                        <li><a routerLink="/blog">Blog</a></li>-->
                    </ul>
                </div>
            </div>
            <!--            Soporte-->
            <!-- <div class="col-lg-2 col-md-6 col-sm-6">
                 <div class="single-footer-widget">
                     <h3>Soporte</h3>
                     <ul class="support-list">
                         <li><a routerLink="/faq">FAQ's</a></li>
                         <li><a routerLink="/">Privacy Policy</a></li>
                         <li><a routerLink="/">Terms & Conditions</a></li>
 &lt;!&ndash;                        <li><a routerLink="/">Community</a></li>&ndash;&gt;

                    </ul>
                </div>
            </div>-->
            <!--            Informacción contacto-->
            <div class="col-lg-4 col-md-6 col-sm-6 flex-center">
                <div class="single-footer-widget">
                    <h3>Información De Contacto</h3>
                    <ul class="footer-contact-info">
                        <li>Ubicación: <a href="https://goo.gl/maps/BQjZwMKSLk9gvxV26" target="_blank">Santiago, Chile</a></li>
                        <li>Email: <a href="mailto:info@mentanalytics.cl">info@mentanalytics.cl</a></li>
                        <li>Teléfono: <a href="https://wa.link/9b9d0g">+569 3116 98 79</a></li>
                    </ul>
                   <!-- <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>-->
                </div>
            </div>
        </div>
        <div class="copyright-area">
            <p>Copyright @2023 Mentanalytics. </p>
        </div>
    </div>
</footer>

<div class="go-top"><i class='bx bx-chevron-up'></i></div>
