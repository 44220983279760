

<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <h2>Contáctanos</h2>
            <p>¿Consultas?Háblanos por cualquiera de nuestros medios de contacto.</p>
        </div>
    </div>
</div>

<section class="contact-area ptb-100">
    <div class="container">
        <div class="contact-inner">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="contact-features-list">
                        <h3>¿Qué idea tienes en mente? </h3>
                        <p>Cuéntanos que es lo que tienes en mente y nosotras/os te ayudamos y brindamos opciones que se adapten a tu necesidades.</p>
                        <p>Cada idea es única y cada una crea una realidad y como empresa queremos ser parte de tu proceso.</p>
                        <ul>
                            <li><i class='bx bx-badge-check'></i> Seguridad</li>
                            <li><i class='bx bx-badge-check'></i> Confidencialidad</li>
                            <li><i class='bx bx-badge-check'></i> Reuniones por videollamadas</li>
                            <li><i class='bx bx-badge-check'></i> Soporte </li>
                            <li><i class='bx bx-badge-check'></i> Consultorías </li>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="contact-form">
                        <h3>Contáctanos</h3>
                        <form [formGroup]="FormData" (ngSubmit)="onSubmit()">
                            <div class="row">
                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="name" id="name" class="form-control" required placeholder="Nombre" formControlName="nombre" >
                                        </div>
                                    <div *ngIf="FormData.get('nombre').invalid && FormData.get('nombre').touched" class="validation-message margin-validation">
                                        Nombre es requerido y debe tener al menos 3 caracteres.
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="email" name="email" id="email" class="form-control" required placeholder="Email" formControlName="email">
                                    </div>
                                    <div *ngIf="FormData.get('email').invalid && FormData.get('email').touched" class="validation-message margin-validation">
                                        El correo electrónico es requerido.
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="phone_number" id="phone_number" required class="form-control" placeholder="Teléfono Contacto" formControlName="telefono">
                                    </div>
                                    <div *ngIf="FormData.get('telefono').invalid && FormData.get('telefono').touched" class="validation-message margin-validation">
                                        El número de contacto es requerido.
                                    </div>
                                </div>

                                <div class="col-lg-6 col-md-6">
                                    <div class="form-group">
                                        <input type="text" name="msg_subject" id="msg_subject" class="form-control" required placeholder="Asunto" formControlName="asunto">
                                    </div>
                                    <div *ngIf="FormData.get('asunto').invalid && FormData.get('asunto').touched" class="validation-message margin-validation">
                                        El campo asunto es requerido.
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <textarea name="message" class="form-control" id="message" cols="30" rows="6" required placeholder="Mensaje" formControlName="mensaje"></textarea>
                                    </div>
                                    <div *ngIf="FormData.get('mensaje').invalid && FormData.get('mensaje').touched" class="validation-message margin-validation">
                                        El campo de mensaje es requerido.
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button
                                        type="submit"
                                        class="default-btn"
                                        [disabled]="formSubmitted"
                                        >
                                        <i class='bx bxs-paper-plane'></i>Enviar Mensaje<span></span>
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="alert alert-success" *ngIf="showSuccessMessage">
                ¡El formulario ha sido enviado exitosamente!
            </div>

            <div class="alert alert-danger" *ngIf="showErrorMessage">
                Hubo un problema al enviar el formulario. Por favor, inténtalo nuevamente más tarde.
            </div>

            <div class="contact-info">
                <div class="contact-info-content">
                    <h3>¡Escríbenos! </h3>
                    <h2>
                        <a class="flex-center" href="https://wa.link/9b9d0g" target="_blank"><i class="bx bxl-whatsapp"></i>+569 3116 98 79</a>
                        <span>Ó</span>
                        <a class="flex-center" href="mailto:info@mentanalytics.cl"> <i class='bx bx-envelope' ></i>info@mentanalytics.cl</a>
                    </h2>
                   <!-- <ul class="social">
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-youtube"></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                    </ul>-->
                </div>
            </div>
        </div>
    </div>
</section>

