import {Injectable, Input} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import axios from 'axios';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class ApiService {
    api = 'https://prod-51.eastus2.logic.azure.com:443/workflows/6e7c1138d9dd4d7d9c8d7cb481048951/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=gG7EkMx5rlasiRqB0lzKxsVXGVds9hB-fgIo1GHOVGE';
    constructor(private http: HttpClient) { }

    apiLogicApp(formData): Observable<any> {
        return this.http.post(this.api, formData);
    }
    }



