import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../services/api.service';
import { FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@angular/forms';
import axios from 'axios';
declare var grecaptcha: any;

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss'],
})
export class ContactComponent implements OnInit {
    FormData: FormGroup;
    private http: any;
    showSuccessMessage: boolean = false;
    showErrorMessage: boolean = false;
    formSubmitted: boolean = false;

    constructor(private apiService: ApiService, private builder: FormBuilder) {}

    ngOnInit() {
        this.FormData = this.builder.group({
            nombre: new FormControl('', [Validators.required, Validators.minLength(3)]),
            email: new FormControl('', [Validators.compose([Validators.required, Validators.email])]),
            asunto: new FormControl('', [Validators.required]),
            mensaje: new FormControl('', [Validators.required]),
            telefono: new FormControl('', [Validators.required]),
        });
        // Suscribirse a los cambios en el formulario
        this.FormData.valueChanges.subscribe(() => {
            this.formSubmitted = false;
        });
    }

    onSubmit() {
        if (this.FormData.valid) {
            // Get the reCAPTCHA token
            grecaptcha.ready(() => {
                grecaptcha.execute('6LewME8nAAAAAHgfT_H0UTUVWk_7fpOidpRcLJYz', { action: 'submit' }).then((token) => {
                    // Include the token in the form data
                    this.FormData.value.token = token;
                    // Send the form data to the server
                    this.apiService.apiLogicApp(this.FormData.value).subscribe(
                        (response) => {
                            console.log('se hizo consulta api logic app');
                            this.showSuccessMessage = true;
                            this.showErrorMessage = false;
                            this.formSubmitted = true;

                            // Disable the form fields after successful submission
                            this.FormData.disable();
                        },
                        (error) => {
                            console.error('Error:', error);
                            this.showSuccessMessage = false;
                            this.showErrorMessage = true;
                        }
                    );
                });
            });
        }
    }
}
